import { useEffect, useState } from "react";
import clsx from "clsx";
import { TextField, Autocomplete, CircularProgress } from "@mui/material";
import { getEntities } from "../../dao/common";
import { useFormContext } from "../../hooks/useFormContext";
import InputFieldType from "../../enums/InputFieldType";
import useDebounce from "../../hooks/UseDebounce";
import { SECTION_REFERENCE, SOURCE_SECTION_REFERENCE } from "./getFieldTypes";
import { useSearchParams } from "../../hooks/useSearchParams";
import { snakeCaseToCamelCase } from "../../utils/utils";

const API_FAILURE_ERROR_MESSAGE =
  "The dropdown couldn't be populated. Please try again by refreshing the page or call ICT department.";

/**
 * Lookup field component
 * How to use:
 * When using this component, you need to consider the following:
 * 1. **Route**: Specify the API route that the component should call.
 * 2. **Dependencies**: Determine if there are dependencies required for the API call, such as `systemGroupId` for calling `/systems`.
 * 3. **Option Label**: Define the field from the API response that should be displayed in the dropdown.
 * 4. **Initial Option Label**: This refers to the field in the parent entity object that will be shown when the form is loaded.
 * 5. **Filter Function** (Optional): Provide a custom function to filter the data before rendering it in the dropdown.
 * 6. **Order** (Optional): Specify how to order the fetched data.
 * 7. **Identifier**: The id name in the response data from the API that will be used to identify the selected item.
 * 8. **Secondary Option Label** (Optional): An additional field to display alongside the primary label.
 * 9. **secondaryFieldNameToBeSet**: The field name that should be set when the primary field is selected.
 * Example usage with withConfig:
 * ```
 * [InputFieldType.LOCATION_LIST]: withConfig({
 *  route: "/locations",
 * order: "number asc",
 * optionLabel: "number", => This is the field name in the response data from GET/locations endpoint that will be displayed in the dropdown
 * initialOptionLabel: "location_reference", => If you render Location dropdown in Equipment detail page, location_reference is available in the Equipment object.
 * dependencies: { objectId: true }, => Location options are dependent on objectId
 * })(Lookup),
 *
 */
export default function Component({
  className,
  formStateHandlers,
  fieldSetting,
  helperText,
  variant,
  required,
  inputProps,
  route = "",
  dependencies,
  filterFn,
  order,
  identifier = "id",
  disabled = false,
  optionLabel = "reference", // reference is the most common field name in the response data from GET/entity for lookup values
  secondaryOptionLabel = "",
  initialOptionLabel,
  formState,
  secondaryFieldNameToBeSet,
  ...props
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [debouncedInputValue, setDebouncedInputValue] = useDebounce("", 1000);
  const params = useSearchParams();

  const {
    objectId,
    projectEntity,
    systemId,
    systemGroupId,
    setSystemEntity,
    setLocationEntity,
    setEquipmentEntity,
    setSystemGroupEntity,
    setCableBundleEntity,
    setProjectEntity,
    setSectionEntity,
  } = useFormContext();

  const initialValue = props.values?.[initialOptionLabel]
    ? {
        [identifier]: props.values?.[fieldSetting?.name],
        [optionLabel]: props.values?.[initialOptionLabel],
        ...(secondaryFieldNameToBeSet && {
          [secondaryFieldNameToBeSet]:
            props.values?.[secondaryFieldNameToBeSet],
        }),
      }
    : null;

  // Initialize data with initialValue if available
  const [data, setData] = useState(initialValue ? [initialValue] : []);

  //  Dependencies
  const dependenciesObjectId = dependencies?.objectId ? objectId : null;
  const dependenciesProjectId = dependencies?.projectId
    ? projectEntity?.id
    : null;
  const dependenciesSystemGroupId = dependencies?.systemGroupId
    ? systemGroupId
    : null;
  const dependenciesSystemId = dependencies?.systemId ? systemId : null;

  useEffect(() => {
    const abortController = new AbortController();
    // Validate route
    if (!route || route.length === 0) {
      throw new Error('prop "route" must have a valid value');
    }

    // Skip API call if required dependencies are missing
    const shouldSkipApiCall =
      (dependencies?.objectId && !dependenciesObjectId) ||
      (dependencies?.projectId && !dependenciesProjectId) ||
      (dependencies?.systemId && !dependenciesSystemId) ||
      (dependencies?.systemGroupId && !dependenciesSystemGroupId);

    if (shouldSkipApiCall) {
      return;
    }

    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const queryParams = {
          limit: 50,
          overview: true,
          withDeleted: false,
          ...(order && { order }),
          ...(dependenciesObjectId && { object_ids: dependenciesObjectId }),
          ...(dependenciesProjectId && { project_ids: dependenciesProjectId }),
          ...(dependenciesSystemGroupId && {
            system_group_ids: dependenciesSystemGroupId,
          }),
          filters: JSON.stringify({
            [optionLabel]: {
              type: "search",
              value: debouncedInputValue || "", // Ensure value is a string
            },
          }),
        };

        const res = await getEntities(route, queryParams, abortController);
        let fetchedData = res.response || [];

        // Check if initial value should be included
        if (initialValue) {
          const initialValueExists = fetchedData.some(
            (responseItem) =>
              responseItem[identifier] === initialValue[identifier]
          );

          // Prepend initial value if not already included
          if (!initialValueExists) {
            fetchedData = [initialValue, ...fetchedData];
          }

          // Set the secondary field on initial render because we need it while updating the entity
          if (secondaryFieldNameToBeSet) {
            formState.setField(
              secondaryFieldNameToBeSet,
              initialValue[secondaryFieldNameToBeSet] ??
                params[snakeCaseToCamelCase(secondaryFieldNameToBeSet)]
            );
          }
        }

        setData(fetchedData);
      } catch (error) {
        console.error("Failed to fetch entities:", error);
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    // Cleanup
    return () => abortController.abort();

    // Dependencies array:
    // - dependenciesObjectId: Used to trigger the effect when `objectId` changes, but only if `objectId` is required.
    // - dependenciesProjectId: Used to trigger the effect when `projectEntity.id` changes, but only if `projectId` is required.
    // - dependenciesSystemGroupId: Used to trigger the effect when `systemGroupId` changes, but only if `systemGroupId` is required.
    // - dependenciesSystemId: Used to trigger the effect when `systemId` changes, but only if `systemId` is required.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dependenciesObjectId,
    dependenciesSystemId,
    dependenciesProjectId,
    dependenciesSystemGroupId,
    debouncedInputValue,
  ]);

  //  Field Configuration
  const { raw } = formStateHandlers;
  const fieldConfig = raw({
    ...fieldSetting,
    onChange: (input) => {
      const value = input.selectValue;
      fieldSetting.onChange({
        target: {
          value,
        },
      });
      return value;
    },
    validate: (value, _values, event) => {
      return !required || !!event.selectValue || !!value;
    },
    compare: () => false,
  });

  //  Filter Items
  const filteredItems = filterFn ? data.filter(filterFn) : data;

  const item = filteredItems.find(
    (item) => item[identifier] === fieldConfig.value
  );

  // Set entity based on the field type, so navigation breadcrumbs can be updated
  switch (props.data.type) {
    case InputFieldType.SYSTEM_GROUP_NUMBER_LIST:
      setSystemGroupEntity(item);
      break;
    case InputFieldType.CABLE_BUNDLE_LIST:
      setCableBundleEntity(item);
      break;
    case InputFieldType.SYSTEM_NUMBER_LIST:
      setSystemEntity(item);
      break;
    case InputFieldType.EQUIPMENT_LIST:
      setEquipmentEntity(item);
      break;
    case InputFieldType.LOCATION_LIST:
      setLocationEntity(item);
      break;
    case InputFieldType.PROJECTS_LIST:
      setProjectEntity(item);
      break;
    case InputFieldType.SECTION_REFERENCE:
      switch (fieldSetting.name) {
        case SECTION_REFERENCE:
        case SOURCE_SECTION_REFERENCE:
          setSectionEntity(item);
          break;
        default:
          break;
      }
      break;

    default:
      break;
  }

  //  Field Props
  const fieldProps = {
    ...fieldConfig,
    ...props,
    onChange: (e, value) => {
      e.selectValue = value ? value[identifier] : null;
      // Set secondary field value if the field is a section reference
      if (props.data.type === InputFieldType.SECTION_REFERENCE) {
        formState.setField(
          secondaryFieldNameToBeSet,
          value ? value.section_id : null
        );
      }
      return fieldConfig.onChange(e);
    },
    id: `lookup_${fieldConfig.name}`,
    className: clsx(className),
  };

  return (
    <Autocomplete
      {...fieldProps}
      options={filteredItems}
      getOptionLabel={(option) => {
        // - option: the current option passed to the Autocomplete component. Can be a string or an object.
        // - identifier: a unique field name used to match options in `filteredItems`.
        // - filteredItems: an array of items that the Autocomplete searches through to find a match.
        // - optionLabel: the primary label field to display for each option.
        // - secondaryOptionLabel: an optional secondary field to display alongside the primary label (e.g., "label + secondaryLabel").
        if (!option) return "";
        const matchedItem = filteredItems.find((item) => {
          if (!option[identifier]) {
            return item[identifier] === option;
          } else {
            return item[identifier] === option[identifier];
          }
        });
        return `${matchedItem?.[optionLabel] ?? ""}${
          matchedItem?.[secondaryOptionLabel]
            ? ` + ${matchedItem?.[secondaryOptionLabel]}`
            : ""
        }`;
      }}
      isOptionEqualToValue={(option, value) => option[identifier] === value}
      autoSelect={true}
      disabled={disabled}
      loading={isLoading}
      loadingText='Loading...'
      onInputChange={(_, __, reason) => {
        if (reason === "clear") {
          setDebouncedInputValue("");
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          {...inputProps}
          label={required ? `${props.label} (required)` : props.label}
          variant={variant}
          required={required}
          error={props.data?.isFieldFailed || props.error}
          helperText={error ? API_FAILURE_ERROR_MESSAGE : helperText}
          onChange={(event) => {
            const inputValue = event.target.value || "";
            setDebouncedInputValue(inputValue);
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress
                    size={20}
                    sx={{ position: "absolute", right: 15 }}
                  />
                ) : (
                  params.InputProps.endAdornment
                )}
              </>
            ),
          }}
          InputLabelProps={{
            shrink: true,
            required: false,
          }}
          fullWidth
        />
      )}
    />
  );
}

import { useEffect, useMemo } from "react";
import { CircularProgress, Paper, Typography } from "@mui/material";
import { useHistory, useParams } from "react-router";
import log from "loglevel";
import { Box } from "@mui/system";

import useFields, { FILTER_TYPES } from "../hooks/useFields";

import useSection from "../hooks/useSection";
import useEquipment from "../hooks/useEquipment";

import EditEntity from "../components/EditEntity";
import {
  addSection,
  softDeleteSection,
  patchSection,
  restoreSection,
} from "../dao/sections";

import useUserPermissionsContext from "../hooks/useUserPermissionsContext";
import UserRights from "../enums/UserRights";

import EntityOverview from "../views/EntityOverview";
import CreateItemButton from "../components/CreateItemButton";
import EditModes from "../enums/EditModes";
import { ROUTES } from "../enums/Routes";
import { useSearchParams } from "../hooks/useSearchParams";
import { addSearchParams } from "../utils/dao";
import useCalculatedSection from "../hooks/useCalculatedSection";

export const SectionDetail = ({ title = "", mode = "" }) => {
  const history = useHistory();

  // update record only
  // get equipmentId from the search params
  const { sectionId } = useParams();

  // create record only
  // get fields from the search params
  const { objectId, systemGroupId, systemId, equipmentId } = useSearchParams();

  const isUpdateMode = mode === EditModes.UPDATE;
  const isCreateMode = mode === EditModes.CREATE;

  const { isAllowedTo, isLoading } = useUserPermissionsContext();

  const isAllowedToCreate = isAllowedTo(
    UserRights.CREATE,
    FILTER_TYPES.section
  );

  const isAllowedToUpdate = isAllowedTo(
    UserRights.UPDATE,
    FILTER_TYPES.section
  );

  const isAllowedToDelete = isAllowedTo(
    UserRights.SOFT_DELETE,
    FILTER_TYPES.section
  );

  const {
    section,
    fetchSectionData,
    isLoading: recordIsLoading,
  } = useSection({
    objectId,
    systemGroupId,
    systemId,
    equipmentId,
    sectionId,
  });

  const { equipment } = useEquipment({
    equipmentId: section.equipment_id,
  });

  const { calculatedSection } = useCalculatedSection({ section, isUpdateMode });

  const [fields] = useFields(
    FILTER_TYPES.section,
    false,
    isCreateMode,
    isUpdateMode
  );

  const searchParams = useMemo(() => {
    return new URLSearchParams(
      Object.entries({
        objectId: section.object_id,
        systemGroupId: section.system_group_id,
        systemId: section.system_id,
        equipmentId: section.equipment_id,
      }).filter(([, value]) => value)
    ).toString();
  }, [
    section.object_id,
    section.system_group_id,
    section.system_id,
    section.equipment_id,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function storeSection(payload) {
    if (isUpdateMode) {
      // Existing Section
      log.trace("about to patch", section.id);
      await patchSection(payload, section.id).then(() => {
        if (
          objectId !== payload.object_id ||
          systemId !== payload.system_id ||
          systemGroupId !== payload.system_group_id ||
          equipmentId !== payload.equipment_id
        ) {
          history.push(["/section", section.id].join("/"));
        } else {
          fetchSectionData();
        }
      });
    } else {
      // Brand new Section
      log.trace("about to create", JSON.stringify(section));
      return addSection(payload).then((res) => {
        history.push(["/section", res.id].join("/"));
      });
    }
  }

  async function removeSection() {
    if (isUpdateMode) {
      return softDeleteSection(section).then(() => {
        fetchSectionData();
      });
    }
  }

  async function cancelChanges() {
    if (isUpdateMode) {
      fetchSectionData();
    } else {
      history.goBack();
    }
  }

  function restoreDeletedSection() {
    restoreSection(section).then(() => {
      fetchSectionData();
    });
  }

  return !fields || recordIsLoading ? (
    <Box
      sx={{
        marginTop: 3,
        textAlign: "center",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Paper
      sx={{
        padding: 4,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        <Typography variant='h5'>{title}</Typography>
        {isUpdateMode && (
          <CreateItemButton
            size='medium'
            entity={FILTER_TYPES.section}
            to={addSearchParams(ROUTES.section.create)(searchParams)}
            isDisabled={isAllowedToCreate === false}
          />
        )}
      </Box>
      {isLoading === false && (
        <>
          <EditEntity
            {...{
              isAllowedToUpdate,
              isAllowedToDelete,
              isAllowedToCreate,
              entity: section,
              fields,
              storeEntity: storeSection,
              deleteEntity: removeSection,
              entityType: FILTER_TYPES.section,
              cancel: cancelChanges,
              restoreEntity: restoreDeletedSection,
              isUpdateMode,
            }}
          />
          {isUpdateMode && (
            <>
              <EntityOverview
                entity={FILTER_TYPES.equipment}
                title={"Equipments overview"}
                nestedEntity={true}
                sectionId={sectionId}
                parent={{
                  object_id: section.object_id,
                  system_group_id: section.system_group_id,
                  section_id: section.id,
                  section_reference: calculatedSection?.calculated_reference,
                  location_id: equipment.location_id, // Location belongs to the parent equipment of the section
                }}
              />
              {/* Where equipmentId is the id of the Section's parent which is used to retrieve the cables */}
              <EntityOverview
                entity={FILTER_TYPES.cable}
                title={"Cables overview"}
                nestedEntity={true}
                sectionId={sectionId}
                equipmentId={equipmentId}
                parent={{
                  object_id: section.object_id,
                  system_group_id: section.system_group_id,
                  system_id: section.system_id,
                  section_id: section.id,
                }}
              />
            </>
          )}
        </>
      )}
    </Paper>
  );
};

export default SectionDetail;
